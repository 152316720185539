import React from "react"
import { Link } from 'gatsby'

import navStyles from "./nav.module.scss"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Nav = () => (
  <header className={navStyles.header}>
    <Link to="#home"><img src="./logo.png" alt="BendTheKneeLogo" className={navStyles.logo} /></Link>
    <input type="checkbox" id="navToggle" className={navStyles.navToggle} />
    <nav>
      <ul>
        <li>
          <a className={navStyles.navItem} href="#listen">Listen</a>
        </li>
        <li>
          <a className={navStyles.navItem} href="#about">About</a>
        </li>
        <li>
          <Link className={navStyles.navItem} to="#contact">Contact</Link>
        </li>
      </ul>
    </nav>
    <label for="navToggle" className={navStyles.navToggleLabel}>
      <span></span>
    </label>
  </header>
)

export default Nav
