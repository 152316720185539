import React from "react"

import Button from "../Button/Button"

import heroStyles from "./hero.module.scss"

const Hero = () => {
  return (
    <div className={heroStyles.container}>
      <div className={heroStyles.subContainer}>
        <h1 className={heroStyles.title}>Bend The Knee</h1>
        <h2 className={heroStyles.subTitle}>
          The essential <em>A Song of Ice and Fire</em> podcast
        </h2>
        <Button text="Listen Now" />
      </div>
    </div>
  )
}

export default Hero
