import React from "react"

import Banner from "../components/Banner/Banner"
import Card from "../components/Card/Card"
import Head from "../components/head"
import Hero from "../components/Hero/Hero"
import Layout from "../components/layout"
import Nav from "../components/Nav/Nav"
import Contact from "../components/Contact/Contact"

import indexStyles from "./index.module.scss"

const HomePage = () => {
  return (
    <Layout>
      <Nav />
      <Head />
      <Hero />
      <Banner
        text="The latest podcasts from Bend The Knee"
        picture="./bannerimg.png"
      />
      <div id="listen" className={indexStyles.podcastSection}>
        <iframe
          className={indexStyles.podcastPlayer}
          src="https://castbox.fm/app/castbox/player/id1335820?v=8.11.3&autoplay=0"
          scrolling="no"
          frameborder="0"
        ></iframe>
      </div>
      <Banner text="Meet the men behind the mics" picture="./westeros.png" />
      <div>
        <div id="about" className={indexStyles.cardSection}>
          <Card
            picture="https://i.imgur.com/9svqFgj.jpg"
            bio="Ser Ezra is a hedge knight at heart. He remains watchful as he pours over Ravens in the Maester's study. Possible long lost nephew of Beric Dondarrion?"
            twitter="https://twitter.com/WompRat_2m"
            instagram="https://t.co/rNYJqbBtSp?amp=1"
            name="Ser Ezra 'The Watchful' "
          />
          <img
            className={indexStyles.cardLogo}
            src="./logo.png"
            alt="bendtheknee"
          />
          <Card
            picture="https://i.imgur.com/8WLiBcu.jpg"
            bio="Ser Matt was forged from iron. His ability to craft theories and pump iron are unmatched throughout the realm. Many know this man simply as 'Gregor Clegainz'"
            twitter="https://twitter.com/SuperGainsBros/"
            instagram="https://t.co/qEv0ANI0y8?amp=1"
            name="Ser Matt 'The Bud Knight' "
          />
        </div>
      </div>

      <Banner
        text="Join the Bend the Knee Community"
        picture="./whitewalkers.png"
      />
      <div id="contact">
        <Contact />
      </div>
    </Layout>
  )
}

export default HomePage
