import React from "react"

import bannerStyles from "./banner.module.scss"

const Banner = ({ text, picture }) => (
  <div
    className={bannerStyles.container}
    style={{
      backgroundImage:
        "linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(" +
        `${picture}` +
        ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: 250,
      width: "100%",
    }}
  >
    <p className={bannerStyles.text}>{text}</p>{" "}
  </div>
)

export default Banner
