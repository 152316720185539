import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Head = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)
    return (
        <Helmet
            title={data.site.siteMetadata.title} >
            <meta name="description" content="Bend the Knee is a podcast all about A Song of Ice and Fire and Game of Thrones!" />
            <meta name="keywords" content="Game of Thrones,Podcast,GRRM, A Song of Ice and Fire, HBO" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css" integrity="sha256-+N4/V/SbAFiW1MPBCXnfnP9QSN3+Keu+NlB+0ev/YKQ=" crossorigin="anonymous" />
        </Helmet>

    )
}

export default Head;