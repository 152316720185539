import React from "react"

import cardStyles from "./card.module.scss"

const Card = props => (
  <div className={cardStyles.card}>
    <div className={cardStyles.profileSidebar}>
      <img className={cardStyles.portrait} src={props.picture} alt="portrait" />
      <ul className={cardStyles.socialMediaList}>
        <li className={cardStyles.socialItem}>
          <a className={cardStyles.socialLink} href={props.twitter}>
            <i className={"fab fa-twitter fa-lg" + ' ' + cardStyles.socialMedia}></i>
          </a>
        </li>
        <li className={cardStyles.socialItem}>
          <a className={cardStyles.socialLink} href={props.instagram}>
            <i className={"fab fa-instagram fa-lg" + ' ' + cardStyles.socialMedia}></i>
          </a>
        </li>
      </ul>
    </div>
    <div className={cardStyles.profileMain}>
      <h2 className={cardStyles.profileName}>{props.name}</h2>
      <p className={cardStyles.profileBody}>{props.bio}</p>
    </div>
  </div >
)

export default Card
