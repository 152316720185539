import React from "react"

import buttonStyles from "./button.module.scss"

const Button = ({ text }) => (
  <a
    href="https://podcasts.apple.com/us/podcast/bend-the-knee-a-song-of-ice-and-fire-podcast/id1273613024"
    className={buttonStyles.button}
  >
    {text}
  </a>
)

export default Button
