import React from "react"
import { navigate } from 'gatsby-link'

import contactStyle from './contactStyles.module.scss'

function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

const Contact = () => {
    const [state, setState] = React.useState({})

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch((error) => alert(error))
    }

    return (


        <div className={contactStyle.contactForm}>
            <div className={contactStyle.formContainer}>
                <h1>Send us your ravens!</h1>
                <form
                    name="contact"
                    method="post"
                    action="/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"

                    onSubmit={handleSubmit}
                >
                    <input type="hidden" name="contact" value="contact" />
                    <p hidden>
                        <label>
                            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                        </label>
                    </p>
                    <div className={contactStyle.formGroup}>
                        <h3>Your Name</h3>
                        <input type="text" name="name" placeholder="Enter Your Name" onChange={handleChange} />
                    </div>
                    <div className={contactStyle.formGroup}>
                        <h3>Subject</h3>
                        <input
                            type="text"
                            name="subject"
                            placeholder="What's your raven about?"
                            onChange={handleChange}
                        />
                    </div>
                    <div className={contactStyle.formGroup}>
                        <h3>Message</h3>
                        <textarea
                            name="message"
                            id=""
                            cols="30"
                            rows="10"
                            placeholder="Let us hear your thoughts"
                            onChange={handleChange}
                        ></textarea>

                    </div>
                    <input type="submit" value="Submit" className={contactStyle.btn} />
                </form>
            </div>
        </div>
    )
}

export default Contact
